import React from 'react'
import { graphql } from 'gatsby'
// import { Helmet } from 'react-helmet'
// import get from 'lodash/get'
import Layout from '../components/layout'
import ArtistNav from '../components/artistNavBar'
import SEO from "../components/seo"
import './artist-bio.css'
// import DOMPurify from 'isomorphic-dompurify';
// import sanitizeHtml from 'sanitize-html';


class ArtistBioTemplate extends React.Component {
    
  render() {
    const artist = this.props.data.contentfulArtist
    
  

    return (
      <Layout location={this.props.location}>
        <SEO 
          title={`${artist.name}`}
          description={`${artist.name } bio and CV `}
        />
        <div style={{ background: '#fff' }}>
          <div className="artistHeader">{artist.name}</div>
            <ArtistNav slug={artist.slug}/>
            {
              artist.bio === null? <h1>Bio Coming Soon </h1>:
              <div className = 'html-cont' dangerouslySetInnerHTML={{__html: artist.bio.childMarkdownRemark.html }} />
              }
        </div>
      </Layout>
    )
  }
}

export default ArtistBioTemplate

export const pageQuery = graphql`
query artistAndArtis($slug: String!) {
  contentfulArtist(slug: {eq: $slug}) {
    name
    slug
    bio {
    childMarkdownRemark {
      html
    }
  }
  }
}
`