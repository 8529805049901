import React from 'react'
import { Link } from "gatsby"
import './artistNavBar.css'

const ArtistNav = (props) => (
    <ul className = 'artist-nav'>
        <li className="nav-li"><Link to={`/artists/${props.slug}/works`} className='artist-nav-link' activeClassName='current'>Works</Link></li>
            <li className="nav-li">|</li>
        <li className="nav-li"><Link to={`/artists/${props.slug}/exhibitions` }className='artist-nav-link' activeClassName='current'>Exhibitions</Link></li>
            <li className="nav-li">|</li>
        <li className="nav-li"><Link to={`/artists/${props.slug}/press` } className='artist-nav-link' activeClassName='current'>Press</Link></li>
            <li className="nav-li">|</li>
        <li className="nav-li"><Link to={`/artists/${props.slug}/bio` } className='artist-nav-link' activeClassName='current'>Bio</Link></li>
    </ul>
)

export default ArtistNav
